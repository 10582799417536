import { filters as filtersKeys } from 'constants/assessmentsFilters';
import { UserRole, DefaultProviderExternalId } from 'constants/defaultValues';
import { filterRoots } from 'constants/filterEnums';
import { getSelectedElementsOnList } from 'helpers/filtersHelpers';
import { sortOrganizations } from 'helpers/organizationsHelpers';
import { getSelectedTenantId } from 'helpers/outcomesHelpers';
import { all, call, put, select } from 'redux-saga/effects';
import { getAggregateAssessments } from 'services/api/outcomes';
import { getGroups, getProviders } from 'services/api/participant';
import { UserSelectors } from 'store/user';
import { OutcomesFeatureFlagsSelectors } from 'store/outcomes-feature-flags';
import {
  INITIAL_STATE,
  AssessmentsActions as Actions,
  AssessmentsSelectors as Selectors,
  AssessmentsTypes as Types,
} from './assessmentsReducer';

function* fetchAssessments() {
  const isFetchAssessmentsLoading = yield select(
    Selectors.getAssessmentsLoading
  );
  if (isFetchAssessmentsLoading) {
    return;
  }

  yield put(Actions.setAssessmentsLoading(true));
  const selectedTenantId = yield select(UserSelectors.getCurrentTenant);
  const tenantList = yield select(UserSelectors.getTenantList);
  const tenantId = getSelectedTenantId(selectedTenantId, tenantList);
  if (!tenantId) {
    return;
  }
  const sort = yield select(Selectors.getOrdering);
  const search = yield select(Selectors.getSearch);
  const filters = yield select(Selectors.getSelectedFilters);
  const navigation = yield select(Selectors.getNavigation);

  try {
    const params = {
      tenantId,
      search,
      filters,
      sort,
      page: navigation.currentPage - 1,
      limit: navigation.limit,
    };

    const response = yield call(getAggregateAssessments, params);
    yield all([
      put(Actions.fetchAssessmentsSuccess(response.data)),
      put(
        Actions.setNavigation({
          currentPage: navigation.currentPage,
          totalPages: Math.ceil(response.totalItems / navigation.limit),
        })
      ),
    ]);
  } catch (error) {
    yield put(
      Actions.setAssessmentsErrors(`[fetchAssessments error]: ${error}`)
    );
  } finally {
    yield put(Actions.setAssessmentsLoading(false));
  }
}

function* getCurrentProvider(action) {
  const role = yield select(UserSelectors.getUserRole);
  if (role.role_id === UserRole.Provider) {
    return {
      value: role.user_id,
      label: role.full_name,
      key: `providers-${role.user_id}`,
    };
  }
  const { allProviders } = action;
  const defaultProvider = allProviders?.find(
    (provider) => provider.external_id === DefaultProviderExternalId
  );
  return defaultProvider ?? allProviders?.[0];
}

let providerController;

function* fetchProviders(action) {
  if (providerController) {
    providerController.abort();
  }
  const selectedFilters = yield select(Selectors.getSelectedFilters);
  const { groupIds, orgIds } = action;
  const search = yield select(Selectors.getSearch);

  yield put(Actions.setFiltersLoading({ [filtersKeys.providers]: true }));
  try {
    providerController = new AbortController();

    const response = yield call(
      getProviders,
      groupIds,
      orgIds,
      providerController.signal
    );

    const providers = response.map((item) => ({
      value: item.user_id,
      label: `${item.first_name} ${item.last_name}`,
      key: `providers-${item.user_id}`,
      external_id: item.external_id,
    }));
    const outcomesFeatures = yield select(
      OutcomesFeatureFlagsSelectors.getOutcomesFeatureFlags
    );
    const currentProviders = outcomesFeatures.IS_BIG_CLIENT
      ? [
          yield getCurrentProvider({
            allProviders: providers,
          }),
        ]
      : [];
    const selectedIsOnProviders = getSelectedElementsOnList(
      providers,
      selectedFilters[filtersKeys.providers]
    );
    const selectedProviders =
      selectedIsOnProviders?.length > 0
        ? selectedIsOnProviders
        : currentProviders;
    yield all([
      put(
        Actions.setSelectedFilters(
          {
            [filtersKeys.providers]: selectedProviders,
          },
          search,
          filterRoots.fetchProviders
        )
      ),
      put(
        Actions.fetchFiltersSuccess({
          [filtersKeys.providers]: providers,
        })
      ),
    ]);
  } catch (error) {
    yield put(Actions.setAssessmentsErrors(`[fetchProviders error]: ${error}`));
  } finally {
    yield put(Actions.setFiltersLoading({ [filtersKeys.providers]: false }));
  }
}

export function* fetchOrganizations(from = filterRoots.fetchOrganizations) {
  const selectedFilters = yield select(Selectors.getSelectedFilters);
  yield put(Actions.setFiltersLoading({ [filtersKeys.organizations]: true }));

  try {
    const response = yield call(getGroups);
    const organizations = response.map((item) => ({
      value: item.id,
      label: item.name,
      key: `organizations-${item.user_id}`,
    }));

    const selectedIncludes = getSelectedElementsOnList(
      organizations,
      selectedFilters[filtersKeys.organizations]
    );
    yield all([
      put(
        Actions.setSelectedFilters(
          {
            [filtersKeys.organizations]: selectedIncludes,
          },
          null,
          from
        )
      ),
      put(
        Actions.fetchFiltersSuccess({
          [filtersKeys.organizations]: sortOrganizations(organizations),
        })
      ),
    ]);
  } catch (error) {
    yield put(
      Actions.setAssessmentsErrors(`[fetchOrganizations error]: ${error}`)
    );
  } finally {
    yield put(
      Actions.setFiltersLoading({ [filtersKeys.organizations]: false })
    );
  }
}

function* fetchFilters() {
  try {
    const role = yield select(UserSelectors.getUserRole);
    const calls = [fetchProviders({ from: filterRoots.fetchFilters })];
    if (
      role.role_id === UserRole.Provider ||
      role.role_id === UserRole.TenantAdmin ||
      role.role_id === UserRole.CareNavigator ||
      role.role_id === UserRole.Admin
    ) {
      calls.unshift(fetchOrganizations({ from: filterRoots.fetchFilters }));
    }
    yield all(calls);
  } catch (error) {
    yield put(Actions.setAssessmentsErrors(`[fetchFilters error]: ${error}`));
  } finally {
    yield put(Actions.setIsFiltersReady(true));
  }
}

function* applySelectedFilters(action) {
  const selectedFilters = yield select(Selectors.getSelectedFilters);
  const selectedNavigation = yield select(Selectors.getNavigation);
  const currentSearch = yield select(Selectors.getSearch);
  const currentSort = yield select(Selectors.getOrdering);

  const { filters } = action;
  const filterKey = Object.keys(filters)[0];
  let filtersToSet = {};

  if (Object.keys(filters).length > 1) {
    filtersToSet = selectedFilters.merge(filters);
  } else {
    filtersToSet = selectedFilters.merge({
      [filterKey]: filters[filterKey],
    });
  }
  yield put(Actions.setSelectedFilters(filtersToSet));

  const providerChanges =
    JSON.stringify(filtersToSet[filtersKeys.providers]) !==
    JSON.stringify(selectedFilters[filtersKeys.providers]);

  const organizationChanges =
    JSON.stringify(filtersToSet[filtersKeys.organizations]) !==
    JSON.stringify(selectedFilters[filtersKeys.organizations]);

  const typeChanges =
    JSON.stringify(filtersToSet[filtersKeys.types]) !==
    JSON.stringify(selectedFilters[filtersKeys.types]);

  const statusChanges =
    JSON.stringify(filtersToSet[filtersKeys.status]) !==
    JSON.stringify(selectedFilters[filtersKeys.status]);

  const ieDateRangeChanges =
    ((filtersToSet[filtersKeys.ieDateRange]?.start &&
      filtersToSet[filtersKeys.ieDateRange]?.end) ||
      filtersToSet[filtersKeys.ieDateRange] === null) &&
    JSON.stringify(filtersToSet[filtersKeys.ieDateRange]) !==
      JSON.stringify(selectedFilters[filtersKeys.ieDateRange]);

  const scheduleDateRangeChanges =
    ((filtersToSet[filtersKeys.scheduldateDateRange]?.start &&
      filtersToSet[filtersKeys.scheduldateDateRange]?.end) ||
      filtersToSet[filtersKeys.scheduldateDateRange] === null) &&
    JSON.stringify(filtersToSet[filtersKeys.scheduldateDateRange]) !==
      JSON.stringify(selectedFilters[filtersKeys.scheduldateDateRange]);

  const includeDischargedChanges =
    JSON.stringify(filtersToSet[filtersKeys.includeDischarged]) !==
    JSON.stringify(selectedFilters[filtersKeys.includeDischarged]);

  if (
    providerChanges ||
    organizationChanges ||
    typeChanges ||
    statusChanges ||
    ieDateRangeChanges ||
    scheduleDateRangeChanges ||
    includeDischargedChanges
  ) {
    yield all([
      put(
        Actions.setNavigation({
          ...selectedNavigation,
          currentPage: 1,
        })
      ),
      put(Actions.fetchAssessments(filtersToSet, currentSearch, currentSort)),
    ]);
  }
}

function* resetFilters() {
  const filters = yield select(Selectors.getFilters);
  const providers = filters[filtersKeys.providers];
  const outcomesFeatures = yield select(
    OutcomesFeatureFlagsSelectors.getOutcomesFeatureFlags
  );
  const currentProviders = outcomesFeatures.IS_BIG_CLIENT
    ? [
        yield getCurrentProvider({
          allProviders: providers,
        }),
      ]
    : [];

  yield all([
    put(
      Actions.setSelectedFilters({
        ...INITIAL_STATE.selectedFilters,
        [filtersKeys.providers]: currentProviders,
      })
    ),
    put(Actions.setNavigation(INITIAL_STATE.navigation)),
    put(Actions.setSearch(INITIAL_STATE.search)),
    put(Actions.setOrdering(INITIAL_STATE.ordering)),
  ]);
}

export default () => ({
  [Types.FETCH_ASSESSMENTS]: fetchAssessments,
  [Types.FETCH_FILTERS]: fetchFilters,
  [Types.FETCH_PROVIDERS]: fetchProviders,
  [Types.APPLY_SELECTED_FILTERS]: applySelectedFilters,
  [Types.RESET_FILTERS]: resetFilters,
});
