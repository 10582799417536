import { filters as filtersKeys } from 'constants/assessmentsFilters';
import { createOutcomesQueryParamsAsString } from 'helpers/requestHelpers';
import moment from 'moment';
import { getOutcomesAssessmentRequest } from './baseOutcomes';

const filterToQueryParam = (filterValues) => {
  if (!filterValues || filterValues.length === 0) {
    return null;
  }
  return filterValues.map((provider) => provider.value);
};

const filterToDateRangeQueryParam = (filterValue) => {
  if (filterValue?.start && filterValue?.end) {
    return {
      startDate: filterValue.start.toISOString(),
      endDate: moment(filterValue.end).endOf('day').toISOString(),
    };
  }
  return { startDate: null, endDate: null };
};

const sortToOrderQueryParam = (sort) => {
  if (sort) {
    const [orderBy, orderDirectionNumber] = sort;
    return {
      orderBy,
      orderDirection: orderDirectionNumber === 1 ? 'ASC' : 'DESC',
    };
  }
  return { orderBy: null, orderDirection: null };
};

export const getAggregateAssessments = async ({
  search,
  filters,
  sort,
  tenantId,
  limit,
  page,
}) => {
  const config = {
    headers: { 'x-tenant-id': tenantId },
  };
  const { startDate: ieStartDate, endDate: ieEndDate } =
    filterToDateRangeQueryParam(filters[filtersKeys.ieDateRange]);
  const { startDate: scheduleStartDate, endDate: scheduleEndDate } =
    filterToDateRangeQueryParam(filters[filtersKeys.scheduldateDateRange]);
  const { orderBy, orderDirection } = sortToOrderQueryParam(sort);
  const queryParams = {
    search,
    limit,
    page,
    providerIds: filterToQueryParam(filters[filtersKeys.providers]),
    organizationIds: filterToQueryParam(filters[filtersKeys.organizations]),
    types: filterToQueryParam(filters[filtersKeys.types]),
    statuses: filterToQueryParam(filters[filtersKeys.status]),
    includeDischarged: filters[filtersKeys.includeDischarged],
    ieStartDate,
    ieEndDate,
    scheduleStartDate,
    scheduleEndDate,
    orderBy,
    orderDirection,
  };
  return getOutcomesAssessmentRequest(
    `api/provider/assessments/aggregate?${createOutcomesQueryParamsAsString(
      queryParams
    )}`,
    config
  ).then((response) => {
    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.statusText);
  });
};

export const getOutcomesFeatureFlags = async ({ tenantId }) => {
  const data = await getOutcomesAssessmentRequest(
    `/api/provider/tenants/${tenantId}/configurations`
  );
  return data.data;
};
