import Immutable from 'seamless-immutable';

export const filters = Immutable({
  providers: 'PROVIDER',
  organizations: 'ORGANIZATION',
  types: 'TYPES',
  status: 'STATUS',
  ieDateRange: 'IE_DATE_RANGE',
  scheduldateDateRange: 'SCHEDULE_DATE_RANGE',
  includeDischarged: 'INCLUDE_DISCHARGED',
});

export const sections = Immutable({
  assessments: 'AGGREGATE_ASSESSMENTS_LIST',
});
