import Immutable from 'seamless-immutable';

// eslint-disable-next-line import/prefer-default-export
export const OUTCOMES_FEATURE_FLAGS = Immutable({
  ENABLE_AGGREGATE_ASSESSMENT_VIEW: 'enableAggregateAssessmentView',
  ALLOW_EDIT_RESPONSE: 'allowEditResponse',
  IS_BIG_CLIENT: 'isBigClient',
});

export const OUTCOMES_ERROR_CODE = Immutable({
  ASSESSMENT_CLONE_CURRENT_REASSESSMENTS_EXIST_ERROR:
    'ASSESSMENT_CLONE_CURRENT_REASSESSMENTS_EXIST_ERROR',
});
